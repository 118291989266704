package com.steamstreet.vegasful.browser.public

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.Element
import org.w3c.dom.asList

/**
 * A really very simple date class for the calendar the mostly doesn't
 * try to do the right thing.
 */
class SimpleDate(val year: Int,
                 val month: Int,
                 val date: Int) : Comparable<SimpleDate> {
    fun plusDay(): SimpleDate {
        var newYear = year
        var newMonth = month
        var newDate = date + 1
        if (date >= 31) {
            newDate = 1
            newMonth++
        }
        if (newMonth > 12) {
            newMonth = 1
            newYear++
        }
        return SimpleDate(newYear, newMonth, newDate)
    }

    override fun toString(): String {
        return "${year}-${month.pad(2)}-${date.pad(2)}"
    }

    override fun compareTo(other: SimpleDate): Int {
        val yearDiff = year - other.year
        return if (yearDiff == 0) {
            val monthDiff = month - other.month
            if (monthDiff == 0) {
                date - other.date
            } else {
                monthDiff
            }
        } else {
            yearDiff
        }
    }

    override fun equals(other: Any?): Boolean {
        return (other is SimpleDate &&
                other.year == year && other.month == month && other.date == date)
    }

    override fun hashCode(): Int {
        return toString().hashCode()
    }
}

fun SimpleDate(str: String): SimpleDate {
    val bits = str.split("-")
    return SimpleDate(bits[0].toInt(), bits[1].toInt(), bits[2].toInt())
}

class SimpleYearMonth(val year: Int, val month: Int) {
    fun plusMonth(): SimpleYearMonth {
        var newYear = year
        var newMonth = month + 1
        if (newMonth > 12) {
            newMonth = 1
            newYear++
        }
        return SimpleYearMonth(newYear, newMonth)
    }

    fun minusMonth(): SimpleYearMonth {
        var newYear = year
        var newMonth = month - 1
        if (newMonth == 0) {
            newMonth = 12
            newYear--
        }
        return SimpleYearMonth(newYear, newMonth)
    }

    override fun toString(): String = "${year}-${month.pad(2)}"
}

fun SimpleYearMonth(str: CharSequence): SimpleYearMonth {
    val bits = str.split("-")
    return SimpleYearMonth(bits[0].toInt(), bits[1].toInt())
}

fun Int.pad(size: Int): String {
    var s = "$this"
    while (s.length < size) {
        s = "0$s"
    }
    return s
}

fun calendarSelector(element: Element) {
    var selectedDate: String? = null

    element.querySelector(".calendar-trigger")?.addEventListener("click", {
        it.preventDefault()
        it.stopPropagation()
        if (element.hasClass("calendar-selection")) {
            element.removeClass("calendar-selection")
        } else {
            element.addClass("calendar-selection")
        }
    })


    /**
     * Clear the date selection by navigating without the date range.
     */
    fun clearDateSelection() {
        val origin = document.location?.origin
        val path = document.location?.pathname
        val url = origin + path
        document.location?.href = url
    }

    /**
     * Handle clicks to the clear date range.
     */
    element.querySelector(".calendar-clear-range")?.addEventListener("click", {
        it.preventDefault()
        it.stopPropagation()

        clearDateSelection()
    })


    fun selectDate(date: String) {
        if (selectedDate != null) {
            val start: String
            val end: String
            if (selectedDate!! < date) {
                start = selectedDate!!
                end = date
            } else {
                start = date
                end = selectedDate!!
            }

            // show all of the dates in this range as selected for visual feedback while we load
            var simpleDate = SimpleDate(start)
            val endSimpleDate = SimpleDate(end)

            while (simpleDate <= endSimpleDate) {
                element.querySelector("[data-calendar-date='${simpleDate}']")?.addClass("calendar-selected")
                simpleDate = simpleDate.plusDay()
            }

            val origin = document.location?.origin
            val path = document.location?.pathname
            val url = origin + path + "?date=${start}&endDate=${end}"
            document.location?.href = url
        } else {
            selectedDate = date
            element.querySelector("[data-calendar-date='${selectedDate}']")?.addClass("calendar-selected")
        }
    }

    element.querySelectorAll("[data-calendar-date]").asList()
            .mapNotNull { it as? Element }
            .forEach { calendarNode ->
                calendarNode.addEventListener("click", {
                    val date = calendarNode.getAttribute("data-calendar-date")
                    if (date != null) {
                        selectDate(date)
                    }
                })
            }

    element.querySelector(".calendar-main")?.let {
//        val min = it.getAttribute("data-calendar-min-month")
//        val max = it.getAttribute("data-calendar-max-month")

        val visibleOne = it.querySelector(".calendar-visible")
        val attribute = visibleOne?.attributes?.asList()?.find { it.name == "data-calendar-month" }?.value
        var selectedMonth = attribute?.let {
            SimpleYearMonth(it)
        }

        fun updateSelection(month: SimpleYearMonth?) {
            if (selectedMonth != null) {
                element.querySelector("[data-calendar-month='${selectedMonth}']")?.removeClass("calendar-visible")
            }
            selectedMonth = month
            if (selectedMonth != null) {
                element.querySelector("[data-calendar-month='${selectedMonth}']")?.addClass("calendar-visible")
            }
        }

        element.querySelectorAll("[data-calendar-nav]").asList().mapNotNull { it as? Element }.forEach {
            val direction = it.getAttribute("data-calendar-nav")
            it.addEventListener("click", { clickEvent ->
                when (direction) {
                    "right" -> updateSelection(selectedMonth?.plusMonth())
                    "left" -> updateSelection(selectedMonth?.minusMonth())
                }
                clickEvent.preventDefault()
            })
        }
    }
}
