package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.bottomShadow
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.css.whenWiderThanPortraitPhone
import kotlinx.css.*
import kotlinx.css.properties.translate
import kotlinx.html.*


class HeroConfiguration(
    val image: String?,
    val title: String?,
)

/**
 * Render a background duotone
 */
fun FlowContent.duotone(
    path: String, top: LinearDimension? = null, left: LinearDimension? = null,
    bottom: LinearDimension? = null, right: LinearDimension? = null,
    showOnMobile: Boolean = false,
    opacity: Number? = null,
    width: LinearDimension? = null,
) {
    div {
        css {
            position = Position.absolute
            top?.let {
                this.top = it
            }
            left?.let {
                this.left = it
            }
            bottom?.let {
                this.bottom = it
            }
            right?.let {
                this.right = it
            }
            width?.let {
                this.width = it
            }
            if (!showOnMobile) {
                display = Display.none
            }
            whenWiderThanPortraitPhone {
                display = Display.block
            }

            opacity?.let {
                this.opacity = it
            }

            "img" {
                width?.let {
                    this.width = it
                }
            }
        }
        img(src = path) {}
    }
}

fun FlowContent.splitHero(configuration: HeroConfiguration, content: (FlowContent.() -> Unit)? = null) {
    div {
        css {
            backgroundColor = VegasfulStyles.darkBlue
            overflow = Overflow.hidden
            position = Position.relative
            zIndex = 65
        }
        div {
            css {
                whenWiderThanPortraitPhone {
                    display = Display.flex
                }

                children {
                    flexGrow = 1.0
                    width = 100.pct
                    zIndex = 65
                }
                if (configuration.title != null) {
                    maxWidth = VegasfulStyles.maxWidth
                    margin(0.px, LinearDimension.auto)
                }
            }
            if (configuration.image != null) {
                div {
                    css {
                        position = Position.relative
                        "> img" {
                            width = 100.pct
                            display = Display.block

                            if (configuration.title == null) {
                                position = Position.absolute
                                top = 50.pct
                                left = 50.pct
                                transform.translate((-50).pct, (-50).pct)
                            } else {
                                position = Position.relative
                                zIndex = 65
                            }
                        }
                        if (configuration.title == null) {
                            height = 455.px
                        }
                    }
                    img(src = configuration.image, classes = "")

                    if (configuration.title != null) {
                        div {
                            css {
                                display = Display.none
                                position = Position.absolute
                                right = (-95).px
                                top = 0.px
                                opacity = 0.5

                                whenWiderThanPortraitPhone {
                                    display = Display.block
                                }
                            }
                            img(src = "/assets/ui/pink-duotone-blob.png") {}
                        }
                    }
                }
            }
            if (configuration.title != null) {
                div {
                    css {
                        position = Position.relative
                        zIndex = 100
                        fontSize = 24.px
                        textAlign = TextAlign.center
                        whenWiderThanPortraitPhone {
                            textAlign = TextAlign.left
                            fontSize = 54.px
                        }
                        padding(20.px)
                        +VegasfulStyles.gradientTitle
                    }
                    +configuration.title
                }
            }
        }

        if (configuration.title != null) {
            duotone("/assets/ui/purple-duotone-top-right.png", top = (-50).px, right = 0.px)
            duotone("/assets/ui/blue-duotone-bottom-right.png", bottom = (-30).px, right = 0.px)
            duotone("/assets/ui/blue-duotone-top-left.png", top = 0.px, left = 0.px)
            duotone(
                "/assets/ui/pink-duotone-bottom-left.png", bottom = (-5).px, left = 0.px, showOnMobile = true,
                opacity = .6
            )
        }
    }

    if (content != null) {
        heroInfo(HeroInfoStyle.LIGHT, HeroInfoOffset.NONE, content)
    }
}

enum class HeroInfoStyle {
    LIGHT,
    DARK
}

enum class HeroInfoOffset {
    NONE,
    DEFAULT
}

/**
 * The area below the hero
 */
fun FlowContent.heroInfo(
    style: HeroInfoStyle,
    offset: HeroInfoOffset = HeroInfoOffset.DEFAULT,
    content: (FlowContent.() -> Unit)
) {
    div {
        css {
            backgroundColor = if (style == HeroInfoStyle.LIGHT) Color.white else VegasfulStyles.darkBlue
            position = Position.relative

            whenWiderThanPortraitPhone {
                maxWidth = VegasfulStyles.maxWidth - (VegasfulStyles.gridWidth * 2)
                if (offset == HeroInfoOffset.DEFAULT) {
                    top = (-30).px
                }
                borderRadius = 2.px
                margin(0.px, LinearDimension.auto)
            }
            zIndex = 65

            whenWiderThanPortraitPhone {
                bottomShadow()
            }

            if (style == HeroInfoStyle.DARK) {
                "a" {
                    color = VegasfulStyles.lightBlue
                }
            }
        }
        div {
            css {
                position = Position.relative
//                overflow = Overflow.hidden
            }
            div {
                css {
                    position = Position.relative
                    zIndex = 65
                }
                content()
            }

            if (style == HeroInfoStyle.DARK) {
                div {
                    css {
                        position = Position.absolute
                        top = 0.px
                        bottom = 0.px
                        left = 0.px
                        right = 0.px
                        overflow = Overflow.hidden
                    }
//                    duotone("/assets/ui/blue-duotone-top-left.png", top = 0.px, left = 0.px, showOnMobile = true)
//                    duotone("/assets/ui/blue-duotone-bottom-right.png", bottom = (-30).px, right = 0.px, opacity = .4)
//
//                duotone(
//                    "/assets/ui/pink-duotone-bottom-left.png", bottom = (-5).px, left = 0.px, showOnMobile = true,
//                    opacity = .6
//                )
                }
            }
        }
    }
}

enum class TitleSize {
    SMALL,
    NORMAL,
    LARGE
}

class StandardHeroInfo {
    var name: String? = null
    var link: String? = null

    var parentName: String? = null
    var parentLink: String? = null

    var description: String? = null

    var titleSize: TitleSize = TitleSize.NORMAL

    var extraContent: (FlowContent.() -> Unit)? = null
}

/**
 * A standard hero info area with a title, description, follow button, etc.
 */
fun FlowContent.standardHeroInfo(
    offset: HeroInfoOffset = HeroInfoOffset.DEFAULT,
    block: StandardHeroInfo.() -> Unit
) {
    val info = StandardHeroInfo().apply(block)
    heroInfo(HeroInfoStyle.DARK, offset) {
        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                whenWiderThanPortraitPhone {
                    flexDirection = FlexDirection.row
                }
                padding(20.px)
                gap = 20.px
            }
            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 10.px
                    whenWiderThanPortraitPhone {
                        width = 65.pct
                    }
                }

                // the name (including the parent
                div {
                    info.parentName?.let {
                        div {
                            css {
                                fontSize = when (info.titleSize) {
                                    TitleSize.LARGE -> 24.px
                                    TitleSize.NORMAL -> 18.px
                                    TitleSize.SMALL -> 14.px
                                }
                                fontWeight = FontWeight.w700
                            }
                            if (info.parentLink != null) {
                                a(href = info.parentLink) {
                                    +info.parentName!!
                                }
                            } else {
                                span {
                                    css {
                                        color = VegasfulStyles.lightBlue
                                    }
                                    +info.parentName!!
                                }
                            }
                        }
                    }
                    div {
                        css {
                            val sizes = when (info.titleSize) {
                                TitleSize.NORMAL -> 54 to 28
                                TitleSize.SMALL -> 36 to 20
                                TitleSize.LARGE -> 54 to 28
                            }
                            fontSize = sizes.second.px
                            whenWiderThanPortraitPhone {
                                fontSize = sizes.first.px
                            }
                            +VegasfulStyles.gradientTitle
                        }
                        if (info.name != null) {
                            +info.name!!
                        }
                    }
                }
                info.description?.let { description ->
                    div {
                        css {
                            color = Color.white
                            fontWeight = FontWeight.w300
                        }
                        descriptionContent(description)
                    }
                }
            }
            div {
                css {
                    fontSize = 14.px
                }
                info.extraContent?.invoke(this)
            }
        }
    }
}
