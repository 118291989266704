package com.steamstreet.vegasful.browser.public

import com.steamstreet.vegasful.browser.account.subscriptions.onClick
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.w3c.dom.Element
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import kotlin.time.Duration.Companion.milliseconds

fun eventDates(element: Element) {
    val action = element.querySelector(".vegasful-pill")
    val popupElement = element.querySelector("[data-event-dates-popup]")
    var showing = false

    fun selectMonth(month: String) {
        popupElement?.querySelectorAll("[data-month-selector-month]")?.asList()?.forEach {
            it.parentElement?.removeAttribute("data-selected")
        }
        val monthPill = popupElement?.querySelector("[data-month-selector-month=\"$month\"]")
        monthPill?.parentElement?.setAttribute("data-selected", "1")

        popupElement?.querySelectorAll("[data-events-month]")?.asList()?.forEach {
            (it as? Element)?.removeAttribute("data-selected")
        }

        popupElement?.querySelector("[data-events-month=\"$month\"]")
            ?.setAttribute("data-selected", "1")
    }

    fun closePopup() {
        popupElement?.removeAttribute("data-selected")
        showing = false
    }

    popupElement?.querySelectorAll("[data-month-selector-month]")?.asList()?.forEach {
        val month = (it as? Element)?.getAttribute("data-month-selector-month")
        if (month != null) {
            it.parentElement?.onClick {
                it.preventDefault()
                selectMonth(month)
            }
        }
    }

    var outsideClickHandler: ((Event) -> Unit)? = null

    outsideClickHandler = { event: Event ->
        console.dir(event)
        val target = event.target as? Element
        if (target != null) {
            // Check if the click is outside the popup
            if (popupElement?.contains(target) != true &&
                target.closest(".popup-trigger") == null
            ) {
                closePopup()
                event.preventDefault()
                event.stopPropagation()

                outsideClickHandler?.let {
                    document.removeEventListener("click", it)
                }
            }
        }
    }

    // Function to show popup and add listener
    suspend fun showPopup() {
        popupElement?.setAttribute("data-selected", "1")

        // select the first month
        popupElement?.querySelectorAll("[data-month-selector-month]")?.asList()?.firstOrNull()?.let {
            val month = (it as? Element)?.getAttribute("data-month-selector-month")
            if (month != null) {
                selectMonth(month)
            }
        }
        delay(10.milliseconds)
        document.addEventListener("click", outsideClickHandler)
        showing = true
    }

    action?.onClick {
        if (!showing) {
            showPopup()
        }
    }
}